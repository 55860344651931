import {TFunction} from "i18next"
import {compact} from "lodash"
import {FC} from "react"
import {useSearchParams} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {Button, Tag} from "@frontend/components"
import chevron from "@frontend/design/icons/chevron-down.svg"
import currencyCircleDollarIcon from "@frontend/design/icons/currency-circle-dollar.svg"
import deliveryIcon from "@frontend/design/icons/delivery.svg"
import homeVector from "@frontend/design/icons/home-vector.svg"
import mapIcon from "@frontend/design/icons/map.svg"
import recycleIcon from "@frontend/design/icons/recycle.svg"
import skillsIcon from "@frontend/design/icons/skills.svg"
import timeIcon from "@frontend/design/icons/time.svg"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import type {RemediationData} from "@ri2/app/server/routers/getRemediationPageData"
import {SkillLevel} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"

import {Price} from "../price"

const doItForMeConsiderItems = (
  skillLevelRequired: SkillLevel | null,
  t: TFunction,
): ConsiderItemProps[] =>
  compact([
    {
      icon: mapIcon,
      label: t("cause.nextStep.doItForMe.localAvailability"),
    },
    skillLevelRequired && {
      icon: skillsIcon,
      label: t("cause.nextStep.doItForMe.skillLevel", {
        skillLevel: t(`cause.nextStep.skillLevels.${skillLevelRequired}`),
      }),
    },
    {
      icon: timeIcon,
      label: t("cause.nextStep.doItForMe.numberOfTechnicianVisits"),
    },
  ])

const diyConsiderItems = (
  skillLevelRequired: SkillLevel | null,
  t: TFunction,
): (ConsiderItemProps | undefined)[] => {
  if (skillLevelRequired) {
    return [
      {
        icon: deliveryIcon,
        label: t("cause.nextStep.diy.shipping"),
      },
      {
        icon: timeIcon,
        label: t("cause.nextStep.diy.age"),
      },
      {
        icon: skillsIcon,
        label: skillLevelRequired
          ? t("cause.nextStep.diy.difficulty", {
              skillLevel: t(`cause.nextStep.skillLevels.${skillLevelRequired}`),
            })
          : t("cause.nextStep.diy.difficultyGeneric"),
      },
    ]
  }

  return [
    {
      icon: timeIcon,
      label: t("cause.nextStep.diy.repairTime"),
    },
    {
      icon: skillsIcon,
      label: t("cause.nextStep.diy.difficultyGeneric"),
    },
  ]
}

const replaceConsiderItems = (
  t: TFunction,
): (ConsiderItemProps | undefined)[] => [
  {
    icon: timeIcon,
    label: t("cause.nextStep.replace.installationTimeframe"),
  },
  {
    icon: currencyCircleDollarIcon,
    label: t("cause.nextStep.replace.installationFees"),
  },
  {
    icon: recycleIcon,
    label: t("cause.nextStep.replace.disposal"),
  },
]

interface ConsiderItemProps {
  label: string
  icon: string
}

const ConsiderItem: FC<ConsiderItemProps> = ({label, icon}) => (
  <div
    className={css({
      minHeight: 20,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 12,
    })}
  >
    <div
      className={css({
        height: 20,
        width: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      })}
    >
      <img src={icon} alt="" />
    </div>
    <div
      className={css({
        textStyle: "captionRegular",
        color: "text.quaternary",
      })}
    >
      {label}
    </div>
  </div>
)

interface Props {
  caseId: string
  causeOnMessageId: number | "peek"
  data: RemediationData
  className?: string
}

export const Remediation: FC<Props> = ({
  caseId,
  causeOnMessageId,
  data: {
    isRecommended,
    type,
    cost,
    skillLevelRequired,
    productTypeMetadata,
    onlyShowPriceRanges,
    enableActionButton: showActionButton,
  },
  className,
}) => {
  const navigateTo =
    type === "diyRemediation"
      ? makePath({
          name: "cases",
          caseId,
          causeOnMessageId,
          diy: true,
        })
      : type === "difmRemediation"
        ? makePath({
            name: "cases",
            caseId,
            causeOnMessageId,
            difm: true,
          })
        : productTypeMetadata.replaceUrl

  const t = useTranslation()

  const {
    setFalse: collapseConsiderationItems,
    setTrue: expandConsiderationItems,
    state: isExpandedConsiderationItems,
  } = useBooleanState(false)

  const considerItems =
    type === "difmRemediation"
      ? doItForMeConsiderItems(skillLevelRequired, t)
      : type === "replaceRemediation"
        ? replaceConsiderItems(t)
        : diyConsiderItems(skillLevelRequired, t)
  const filteredConsiderItems = considerItems.filter(
    (labelAndIcon): labelAndIcon is ConsiderItemProps => !!labelAndIcon,
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()

  const onReplace = (): void => {
    setSearchParams({replace: "true"})
  }

  return (
    <div
      className={cx(
        css({
          overflow: "visible",
          position: "relative",
        }),
      )}
    >
      {isRecommended && (
        <div
          className={css({
            position: "absolute",
            top: "-19px",
            right: "-20px",
            largeDesktop: {
              top: "-23.29px",
              right: "-24.93px",
            },
            desktop: {
              top: "-13.76px",
              right: "-14.76px",
            },
            zIndex: "causes.sparkle",
          })}
        >
          <ReactSVG
            src={homeVector}
            className={cx(
              iconsColor({color: "background.brand.accent"}),
              css({
                "& svg": {
                  width: "40px",
                  height: "40px",
                  largeDesktop: {
                    width: "49.86px",
                    height: "49.86px",
                  },
                  desktop: {
                    width: "30.72px",
                    height: "30.72px",
                  },
                },
              }),
            )}
          />
        </div>
      )}
      <div
        className={cx(
          css(
            {
              borderRadius: 12,
              display: "flex",
              flexDirection: "column",
              backgroundColor: "background.subtle.primary",
              borderWidth: 1,
              borderColor: "border.primary.300",
              padding: 20,
              largeDesktopOnly: {
                "@media (max-height: 800px)": {
                  paddingBottom: 0,
                  overflow: "auto",
                },
              },
            },
            isRecommended && {
              backgroundColor: "background.primary",
              boxShadow:
                "0px 9px 20px rgba(28, 37, 72, 0.14), 0px 3px 3px rgba(28, 37, 72, 0.06)",
            },
          ),
          className,
        )}
      >
        <div
          className={vstack({
            flex: 1,
            alignItems: "stretch",
            gap: {
              base: 28,
              largeDesktop: 36,
            },
            "@media (max-height: 800px)": {
              largeDesktop: {
                gap: 28,
              },
            },
          })}
        >
          <div
            className={hstack({
              justifyContent: "space-between",
              minHeight: 24,
              flexWrap: "wrap",
            })}
          >
            <span
              className={css({
                textStyle: "bodyLargeMedium",
                color: "text.primary",
                flexShrink: 0,
              })}
            >
              {t(`cause.nextStep.titles.${type}`) as string}
            </span>
            {isRecommended && (
              <Tag
                title={t("cause.nextStep.recommended")}
                className={css({flexShrink: 0})}
                colorScheme="orange"
              />
            )}
          </div>
          <div
            className={cx(
              vstack({
                gap: 28,
                alignItems: "flex-start",
                "@media (max-height: 800px)": {
                  gap: 8,
                },
              }),
              css(
                // remove marginBottom when is not expanded to avoid extra space and unnecessary scroll
                isExpandedConsiderationItems
                  ? {
                      "@media (max-height: 800px)": {
                        largeDesktop: {
                          marginBottom: 0,
                        },
                      },
                    }
                  : {
                      "@media (max-height: 800px)": {
                        largeDesktop: {
                          marginBottom: -28,
                        },
                      },
                    },
              ),
            )}
          >
            <Price
              cost={cost}
              type={type}
              onlyShowRange={onlyShowPriceRanges}
            />
            <button
              onClick={
                isExpandedConsiderationItems
                  ? collapseConsiderationItems
                  : expandConsiderationItems
              }
              className={hstack({
                display: "none",
                largeDesktopOnly: {
                  "@media (max-height: 800px)": {display: "flex", paddingY: 8},
                },
                justifyContent: "flex-start",
                alignItems: "center",
                gap: 8,
              })}
            >
              <ReactSVG
                src={chevron}
                className={css({
                  transform: isExpandedConsiderationItems
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                })}
              />
              <h3
                className={css({
                  textStyle: "bodyRegular",
                  color: "text.tertiary",
                })}
              >
                {t("cause.nextStep.moreToConsider")}
              </h3>
            </button>
            <div
              className={cx(
                css({
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  desktopDown: {
                    display: "none",
                  },
                }),
                isExpandedConsiderationItems
                  ? css({
                      "@media (max-height: 800px)": {
                        display: "flex",
                      },
                    })
                  : css({
                      "@media (max-height: 800px)": {
                        display: "none",
                        position: "absolute",
                      },
                    }),
              )}
            >
              <h3
                className={css({
                  textStyle: "bodyRegular",
                  color: "text.tertiary",
                  "@media (max-height: 800px)": {display: "none"},
                })}
              >
                {t("cause.nextStep.moreToConsider")}
              </h3>
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                })}
              >
                {filteredConsiderItems.map(({label, icon}) => (
                  <ConsiderItem label={label} icon={icon} key={icon} />
                ))}
              </div>
            </div>
          </div>
          <div
            className={css(
              {
                marginTop: "auto",
                largeDesktopOnly: {
                  "@media (max-height: 800px)": {
                    position: "sticky",
                    paddingBottom: 20,
                    bottom: 0,
                  },
                },
              },
              isRecommended
                ? {backgroundColor: "white"}
                : {backgroundColor: "rgba(250, 250, 250, 1)"},
            )}
          >
            <Button
              id={idForActionButton(type)}
              navigateTo={navigateTo}
              disabled={!showActionButton}
              onClick={type === "replaceRemediation" ? onReplace : undefined}
              title={t(`cause.nextStep.action.${type}`) as string}
              variant={isRecommended ? "primary" : "secondary"}
              fullWidth
              external={type === "replaceRemediation"}
              rightElement={
                isRecommended && (
                  <ReactSVG
                    src={chevron}
                    className={css({
                      transform: "rotate(-90deg)",
                    })}
                  />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const idForActionButton = (type: string): string => `remediation-action-${type}`
