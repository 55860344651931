import {FC, useState} from "react"
import {ReactSVG} from "react-svg"

import {Button, Modal} from "@frontend/components"
import contactIcon from "@frontend/design/icons/contact.svg"
import {useTranslation} from "@frontend/i18n"
import {getNormalizedPhoneNumber} from "@frontend/utils/getNormalizedPhoneNumbers"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"

import {SidebarButton} from "./sidebar-button"

interface Props {
  isSidebarOpen: boolean
  afterClick: () => void
}

export const ContactButton: FC<Props> = ({isSidebarOpen, afterClick}) => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const onClick = (): void => {
    setIsOpenModal(true)
    afterClick()
  }
  const t = useTranslation()

  const onCloseModal = (): void => setIsOpenModal(false)

  return (
    <>
      <ContactUsModal onCloseModal={onCloseModal} isOpenModal={isOpenModal} />
      <SidebarButton
        icon={contactIcon}
        title={t("appContainer.sidebar.contactUs")}
        onClick={onClick}
        isSidebarOpen={isSidebarOpen}
      />
    </>
  )
}

interface ContactUsModalProps {
  isOpenModal: boolean
  onCloseModal: () => void
}

const ContactUsModal: FC<ContactUsModalProps> = ({
  isOpenModal,
  onCloseModal,
}) => {
  const t = useTranslation()
  const phoneNumber: string = t(
    "appContainer.sidebar.contactUsModal.phoneNumber",
  )

  const phoneLink = `tel:${getNormalizedPhoneNumber(phoneNumber)}`

  return (
    <Modal
      title={t("appContainer.sidebar.contactUsModal.title")}
      show={isOpenModal}
      onDismiss={onCloseModal}
      containerCss={css.raw({
        paddingBottom: 16,
        paddingTop: 24,
      })}
      width={610}
      icon={
        <div
          className={css({
            borderRadius: 8,
            backgroundColor: "background.brand.primarySubtle",
            padding: 6,
          })}
        >
          <ReactSVG
            src={contactIcon}
            className={iconsColor({color: "foreground.brand.primary"})}
          />
        </div>
      }
    >
      <div
        className={vstack({
          gap: 16,
        })}
      >
        <p
          className={css({
            padding: 0,
            textStyle: {
              base: "body2",
              desktop: "body",
            },
            color: "fontGrey",
          })}
        >
          {t("appContainer.sidebar.contactUsModal.customerSupportDescription")}
        </p>
        <div
          className={cx(
            vstack({gap: 8}),
            css({
              backgroundColor: "lightGrey",
              padding: 20,
              borderRadius: 12,
              width: "100%",
            }),
          )}
        >
          <a href={phoneLink} className={css({textStyle: "h3"})}>
            {phoneNumber}
          </a>
        </div>
      </div>
      <div className={hstack({justifyContent: "flex-end"})}>
        <Button
          variant="secondary"
          onClick={onCloseModal}
          size="large"
          title={t("general.ok")}
          css={css.raw({
            width: {
              base: "100%",
              desktop: 150,
            },
          })}
        />
      </div>
    </Modal>
  )
}
