import {FC} from "react"

import {notFound} from "@frontend/utils/notFound"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Steps} from "../components/steps"
import {useDataFromParams} from "../params"

export const StepsPage: FC = () => {
  const {remediationPageData, diyPageData: diyContent} = useDataFromParams()
  if (remediationPageData.type.type !== "remediation") {
    notFound()
    return null
  }

  return (
    <div
      className={hstack({
        alignItems: "stretch",
        gap: 35,
        minHeight: 0,
        desktopDown: {
          marginX: 16,
          marginTop: 24,
        },
      })}
    >
      <Steps
        steps={diyContent.steps}
        productType={remediationPageData.type.cause.productType}
        enrichedProductInfo={remediationPageData.productInfo}
        className={css({
          paddingTop: 16,
          paddingX: 16,
        })}
      />
    </div>
  )
}
