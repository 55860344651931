import {TFunction} from "i18next"

import {Breadcrumb} from "@frontend/app-container/context"
import {makePath} from "@frontend/routing"

import {makeBreadcrumbs as parentMakeBreadcrumbs} from "../../../util/breadcrumbs"

export const makeBreadcrumbs = ({
  caseId,
  caseName,
  cause,
  remediationType,
  t,
  queryParams,
}: {
  caseId: string
  caseName: string
  cause: {id: number; name: string} | "peek"
  remediationType?: "doItForMe" | "doItYourself"
  queryParams?: string
  t: TFunction
}): Breadcrumb[] => {
  const breadcrumbs = [
    ...parentMakeBreadcrumbs({
      caseId,
      caseName,
      queryParams,
    }),
    {
      name: cause === "peek" ? "TODO" : cause.name,
      path: makePath({
        name: "cases",
        caseId,
        causeOnMessageId: cause === "peek" ? "peek" : cause.id,
      }),
      hidden: true,
    },
  ]

  return remediationType
    ? [
        ...breadcrumbs,
        {
          name: t(
            `appContainer.header.breadcrumbs.remediations.${remediationType}`,
          ),
          path:
            remediationType === "doItForMe"
              ? makePath({
                  name: "cases",
                  caseId,
                  causeOnMessageId: cause === "peek" ? "peek" : cause.id,
                  difm: true,
                })
              : makePath({
                  name: "cases",
                  caseId,
                  causeOnMessageId: cause === "peek" ? "peek" : cause.id,
                  diy: true,
                }),
          hidden: true,
        },
      ]
    : breadcrumbs
}
