import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"

import {Document} from "./components/document"
import {Empty} from "../components/empty"
import {useDataFromParams} from "../params"

export const DocumentsPage: FC = () => {
  const {diyPageData: diyContent} = useDataFromParams()

  const t = useTranslation()

  if (diyContent.documents.length === 0) {
    return (
      <Empty
        message={t("cause.diy.empty.documents")}
        className={css({
          alignSelf: "center",
          height: "100%",
        })}
      />
    )
  }

  return (
    <div
      className={css({
        display: "grid",
        gridTemplateColumns: {
          base: "repeat(auto-fit, minmax(220px, 1fr))",
          desktop: "repeat(6, 1fr)",
        },
        gridAutoRows: "1fr",
        gridAutoFlow: "row",
        gap: 24,
        marginTop: 30,
        overflowY: "auto",
        maxHeight: {
          base: "auto",
          desktop: "95%",
        },
        desktopDown: {
          marginX: 16,
          marginTop: 24,
        },
      })}
    >
      {diyContent.documents.map((document, index) => (
        <Document document={document} key={index} />
      ))}
    </div>
  )
}
