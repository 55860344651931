import {FC} from "react"

import {css} from "@styled-system/css"

import {CauseIcon, CauseIconCircle} from "./cause-icon"

interface Props {
  iconNames: (string | null)[]
}

const MAX_VISIBLE = 3

export const CauseIcons: FC<Props> = ({iconNames}) => {
  const visibleIcons = iconNames.slice(0, MAX_VISIBLE)
  const remainingCount = iconNames.length - MAX_VISIBLE

  return (
    <div className={css({position: "relative"})}>
      {visibleIcons.map((iconName, index) => (
        <CauseIcon
          key={index}
          color="colors.background.brand.solidSubtle"
          iconColor="foreground.primaryOnSolid"
          borderColor="colors.background.brand.solid"
          iconName={iconName}
          className={
            index > 0 ? css({position: "absolute", top: 0}) : undefined
          }
          style={
            index > 0
              ? {
                  left: `${index * 40}px`,
                }
              : undefined
          }
        />
      ))}
      {remainingCount > 0 && (
        <CauseIconCircle
          color="colors.background.brand.solidSubtle"
          borderColor="colors.background.brand.solid"
          className={css({
            position: "absolute",
            top: 0,
            textStyle: "bodyLargeMedium",
            color: "foreground.primaryOnSolid",
          })}
          style={{
            left: `${MAX_VISIBLE * 40}px`,
          }}
        >
          {"+"}
          {remainingCount}
        </CauseIconCircle>
      )}
    </div>
  )
}
