import {FC} from "react"

import {ReactionsWithForm} from "@frontend/components"
import {useTranslation} from "@frontend/i18n"
import {Feedback, Recommendation} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack} from "@styled-system/patterns"

import {Reason} from "./reason"

interface Props {
  caseId: string
  causeId: string
  recommendation: Recommendation
  feedback?: Feedback
  openModal: () => void
}

export const RecommendationBasedOn: FC<Props> = ({
  recommendation,
  caseId,
  causeId,
  feedback,
  openModal,
}) => {
  const t = useTranslation()
  return (
    <>
      <div
        className={hstack({
          width: "100%",
          justifyContent: "space-between",
        })}
      >
        <div
          className={hstack({
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 8,
            desktopDown: {
              flexWrap: "wrap",
            },
          })}
        >
          <span
            className={css({
              textStyle: "captionRegular",
              color: "text.tertiary",
              desktopDown: {
                width: "100%",
              },
            })}
          >
            {t("cause.nextStep.recomendationBasedOn")}
          </span>
          {recommendation.reasons.map((reason) => (
            <Reason key={reason} reason={reason} />
          ))}
          <button
            onClick={openModal}
            className={css({
              textStyle: "captionMedium",
              color: "text.brand.accent",
              cursor: "pointer",
            })}
          >
            {t("cause.nextStep.learnMore")}
          </button>
        </div>
        <div
          className={css({
            display: "none",
            desktop: {
              display: "block",
            },
          })}
        >
          <ReactionsWithForm
            on={{
              on: "remediation",
              caseId,
              causeId,
              type: "RECOMMENDATION",
            }}
            currentFeedback={feedback}
            className={css({marginRight: {base: 16, desktop: 0}})}
          />
        </div>
      </div>
    </>
  )
}
