import {Feedback as BaseFeedback} from "@prisma/client"
import {z} from "zod"

export type {
  FeedbackRating,
  FeedbackOnRemediation,
  FeedbackOnRemediationType,
} from "@prisma/client"

export type Feedback = Omit<BaseFeedback, "reasons"> & {
  reasons: SelectedReasons | null
}

export const SelectedReasonsParser = z.record(
  z.union([z.boolean(), z.record(z.boolean())]),
)

export type SelectedReasons = z.infer<typeof SelectedReasonsParser>
