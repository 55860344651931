import {ComponentProps, FC, PropsWithChildren} from "react"
import {ReactSVG} from "react-svg"

import backgroundPattern from "@frontend/design/icons/background-pattern-green.svg"
import {css, cx} from "@styled-system/css"
import {vstack} from "@styled-system/patterns"

import {BackBar} from "./back-bar"

interface Props {
  reactionsProps?: ComponentProps<typeof BackBar>["reactionsProps"]
  className?: string
}

export const Sidebar: FC<PropsWithChildren<Props>> = ({
  reactionsProps,
  className,
  children,
}) => (
  <div
    className={cx(
      vstack({
        alignItems: "stretch",
        position: "relative",
        gap: 16,
        paddingBottom: {
          base: 32,
          desktop: 24,
        },
        paddingX: 32,
        backgroundColor: "background.brand.solid",
      }),
      className,
    )}
  >
    <div
      className={css({
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        borderTopRightRadius: 17,
        overflow: "hidden",
        "& svg": {
          width: "747px",
          height: "540px",
        },
      })}
    >
      <ReactSVG src={backgroundPattern} />
    </div>
    <BackBar
      reactionsProps={reactionsProps}
      className={css({
        flexGrow: 0,
        flexShrink: 0,
        top: 0,
        zIndex: "remediations.backButton",
      })}
    />
    <div
      className={css({
        height: 1,
      })}
    />
    <div className={css({zIndex: "remediations.backButton"})}>{children}</div>
  </div>
)
