import type {ProductType} from "@ri2/rc-db"
import {TFunction} from "i18next"
import {FC, useRef} from "react"

import {InformationBanner} from "@frontend/components/ui/information-banner"
import {useTranslation} from "@frontend/i18n"
import type {
  DiyStepsWithSource,
  EnrichedProductInfo,
  Source,
} from "@ri2/db/client"
import {css, cx} from "@styled-system/css"

import {Step} from "./step"

const idForStep = (index: number): string => `step-${index}`

interface Props {
  productType: ProductType
  steps: DiyStepsWithSource | null
  enrichedProductInfo: EnrichedProductInfo
  className?: string
}

export const Steps: FC<Props> = ({
  productType,
  steps,
  enrichedProductInfo,
  className,
}) => {
  const t = useTranslation()

  const containerRef = useRef<HTMLDivElement>(null)

  if (!steps || steps.steps.length === 0) {
    return (
      <div className={cx(className)}>
        <InformationBanner
          content={t("cause.diy.empty.guide")}
          variant="informational"
        />
      </div>
    )
  }

  return (
    <>
      <div
        className={css({
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: {
            base: 32,
            largeDesktop: 24,
          },
          overflowY: {
            base: undefined,
            largeDesktop: "auto",
          },
          paddingBottom: 24,
          largeDesktop: {
            paddingRight: 12,
            overflowY: "auto",
          },
        })}
        ref={containerRef}
      >
        {(steps.source.type === "productType" ||
          steps.source.type === "brand") && (
          <InformationBanner
            content={informationBannerContent(
              productType,
              steps.source,
              enrichedProductInfo,
              t,
            )}
            variant="informational"
          />
        )}
        {steps.steps.map((step, index) => (
          <Step step={step} index={index} id={idForStep(index)} key={index} />
        ))}
      </div>
    </>
  )
}

const informationBannerContent = (
  productType: ProductType,
  source: Source,
  enrichedProductInfo: EnrichedProductInfo,
  t: TFunction,
): string | null => {
  if (source.type === "match") {
    return null
  }

  if (source.type === "productType") {
    return t(
      enrichedProductInfo.modelNumber
        ? "cause.diy.guide.dataQuality.generic.withModelNumber"
        : "cause.diy.guide.dataQuality.generic.withoutModelNumber",
      {
        productType: productType.name.toLowerCase(),
      },
    )
  }

  return t(
    enrichedProductInfo.modelNumber
      ? "cause.diy.guide.dataQuality.borrow.withModelNumber"
      : "cause.diy.guide.dataQuality.borrow.withoutModelNumber",
  )
}
