import {FC} from "react"

import {useTranslation} from "@frontend/i18n"
import {Cost, RemediationType} from "@ri2/db/client"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {DataQualityTag} from "../diy/components/data-quality-tag"

type Props = {
  type: RemediationType
  cost: Cost | null
  onlyShowRange?: boolean
}

export const Price: FC<Props> = ({cost, type, onlyShowRange}) => {
  const t = useTranslation()

  if (!cost) {
    return <NoPrice type={type} />
  }

  const priceString =
    onlyShowRange && cost.range
      ? t("general.priceRangeNoCents", {
          bottom: cost.range.low,
          top: cost.range.high,
        })
      : t(
          type === "diyRemediation" ? "general.price" : "general.priceNoCents",
          {
            price: cost.cost,
          },
        )

  const isDiyWithNoParts = type === "diyRemediation" && cost.cost === 0

  return (
    <div
      className={css({
        display: "flex",
        flexDirection: "column",
        gap: 4,
        minHeight: 100,
      })}
    >
      <div
        className={hstack({
          gap: 16,
          alignItems: "center",
          marginBottom: 12,
        })}
      >
        <div
          className={css({
            textStyle: "header.m",
            color: "text.primary",
          })}
        >
          {type !== "replaceRemediation" && cost.source.type !== "match" && (
            <span
              className={css({
                color: "text.primary",
              })}
            >
              {"~"}
            </span>
          )}
          {priceString}
        </div>
        {!onlyShowRange && cost.range && (
          <div
            className={vstack({height: 32, gap: 0, alignItems: "flex-start"})}
          >
            <p
              className={css({
                textStyle: "captionRegular",
                color: "text.quaternary",
              })}
            >
              {t("cause.nextStep.replace.fullRange")}
            </p>
            <span
              className={css({
                textStyle: "captionRegular",
                color: "text.quaternary",
              })}
            >
              {t("general.priceRangeNoCents", {
                bottom: cost.range.low,
                top: cost.range.high,
              })}
            </span>
          </div>
        )}
      </div>
      <DataQualityTag
        source={cost.source}
        overrideColorScheme={
          type === "replaceRemediation" || isDiyWithNoParts
            ? "positive"
            : undefined
        }
        overrideTitle={
          isDiyWithNoParts
            ? t("cause.nextStep.dataQuality.price.noPartsRequired")
            : undefined
        }
        className={css({alignSelf: "flex-start"})}
      />
      <div
        className={css({
          color: "text.quaternary",
          textStyle: "captionRegular",
        })}
      >
        {
          t(
            isDiyWithNoParts
              ? "cause.nextStep.priceLabel.doItYourselfNoParts"
              : type === "replaceRemediation"
                ? "cause.nextStep.priceLabel.replace"
                : type === "difmRemediation"
                  ? "cause.nextStep.priceLabel.doItForMe"
                  : "cause.nextStep.priceLabel.doItYourself",
          ) as string
        }
      </div>
    </div>
  )
}

const NoPrice: FC<Pick<Props, "type">> = ({type}) => {
  const t = useTranslation()

  return (
    <div
      className={vstack({
        height: 80,
        paddingX: 30,
        paddingY: 10,
        justifyContent: "center",
        textStyle: "caption",
        color: "darkGrey",
        borderWidth: 1,
        borderStyle: "dashed",
        borderColor: "darkGrey",
        borderRadius: 4,
        textAlign: "center",
      })}
    >
      {t(`cause.nextStep.noPrice.${type}`) as string}
    </div>
  )
}
