import type {Product} from "@ri2/rc-db"
import {QueryKey, UseQueryOptions} from "@tanstack/react-query"
import {createTRPCReact} from "@trpc/react-query"
import {UseQueriesProcedureRecord} from "@trpc/react-query/shared"

import type {AppRouter} from "@ri2/app/server/routers/_app"
import {Feedback} from "@ri2/db/client"

export const trpc = createTRPCReact<AppRouter>({})

/* eslint-disable @typescript-eslint/no-explicit-any */

type GetOptions<TQueryOptions> =
  TQueryOptions extends UseQueryOptionsForUseQueries<any, any, any, any>
    ? TQueryOptions
    : never

type QueriesOptions<
  TQueriesOptions extends any[],
  TResult extends any[] = [],
> = TQueriesOptions extends []
  ? []
  : TQueriesOptions extends [infer Head]
    ? [...TResult, GetOptions<Head>]
    : TQueriesOptions extends [infer Head, ...infer Tail]
      ? QueriesOptions<Tail, [...TResult, GetOptions<Head>]>
      : unknown[] extends TQueriesOptions
        ? TQueriesOptions
        : TQueriesOptions extends UseQueryOptionsForUseQueries<
              infer TQueryFnData,
              infer TError,
              infer TData,
              infer TQueryKey
            >[]
          ? UseQueryOptionsForUseQueries<
              TQueryFnData,
              TError,
              TData,
              TQueryKey
            >[]
          : UseQueryOptionsForUseQueries[]

type UseQueryOptionsForUseQueries<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, "context">

type QueriesResults<
  TQueriesOptions extends UseQueryOptionsForUseQueries<any, any, any, any>[],
> = {
  [TKey in keyof TQueriesOptions]: TQueriesOptions[TKey] extends UseQueryOptionsForUseQueries<
    any,
    any,
    infer TData,
    any
  >
    ? TData
    : never
}

export const useSuspenseQueries = <
  TQueryOptions extends UseQueryOptionsForUseQueries<any, any, any, any>[],
>(
  queriesCallback: (
    t: UseQueriesProcedureRecord<AppRouter>,
  ) => readonly [...QueriesOptions<TQueryOptions>],
): QueriesResults<TQueryOptions> =>
  trpc.useQueries(queriesCallback).map((result) => result.data) as any

/* eslint-enable @typescript-eslint/no-explicit-any */

export const useCreateCaseMutation = (
  onSuccess?: (result: {caseId: string}) => void,
): ReturnType<typeof trpc.createCase.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.createCase.useMutation({
    onSuccess: ({caseId}, {userRcId}) => {
      invalidateCasePageQueries(utils, {
        userRcId,
      })

      onSuccess?.({caseId})
    },
  })
}

export const useAddToCartMutation = (): ReturnType<
  typeof trpc.addToCart.useMutation
> => trpc.addToCart.useMutation()

export const invalidateCasePageQueries = (
  utils: ReturnType<typeof trpc.useUtils>,
  {
    userRcId,
    caseId,
  }: {
    userRcId: string
    caseId?: string
  },
): void => {
  utils.caseSummaries.invalidate({
    userRcId,
  })

  if (caseId) {
    utils.casePageData.invalidate({
      id: caseId,
    })
  }
}

export const useCreateFeedbackMutation = (
  onSuccess?: (feedback: Feedback) => void,
): ReturnType<typeof trpc.createFeedback.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.createFeedback.useMutation({
    onSuccess: (feedback, {on}) => {
      if (on) {
        utils.remediationPageData.invalidate()
        utils.casePageData.invalidate()
      }

      onSuccess?.(feedback)
    },
  })
}

export const useUpdateFeedbackMutation = (
  onSuccess?: (feedback: Feedback) => void,
): ReturnType<typeof trpc.updateFeedback.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.updateFeedback.useMutation({
    onSuccess: (feedback) => {
      utils.remediationPageData.invalidate()
      utils.casePageData.invalidate()

      onSuccess?.(feedback)
    },
  })
}

export const useLookUpModelNumber = (
  onSuccess?: (product: Product | null) => void,
): ReturnType<typeof trpc.lookUpModelNumber.useMutation> =>
  trpc.lookUpModelNumber.useMutation({
    onSuccess: (product) => {
      onSuccess?.(product)
    },
  })

export const useAddModelNumberToCase = (
  onSuccess?: () => void,
): ReturnType<typeof trpc.addModelNumberToCase.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.addModelNumberToCase.useMutation({
    onSuccess: () => {
      // reset rather than invalidate here because we want to force
      // loading screens until the data is fresh again
      utils.casePageData.reset()
      utils.remediationPageData.reset()

      onSuccess?.()
    },
  })
}

type BetaAccessState = "pending" | "hasAccess" | "noAccess"

// Since the beta in production is closed, just assume the user has beta access
// This can be reversed if/when we re-launch
export const useHasBetaAccess = (): BetaAccessState => "hasAccess"
// export const useHasBetaAccess = (): BetaAccessState => {
//   const [state, setState] = useState<BetaAccessState>("pending")
//
//   const tryGrantBetaAccess = trpc.tryGrantBetaAccess.useMutation({
//     onSuccess: (granted) => {
//       if (granted) {
//         setState("hasAccess")
//       } else {
//         setState("noAccess")
//       }
//     },
//   })
//
//   const {userRcId} = useSession()
//
//   useEffectOnMount(() => {
//     tryGrantBetaAccess.mutate({userRcId})
//   })
//
//   return state
// }

export const useRequestBetaAccessMutation = (
  onSuccess?: () => void,
): ReturnType<typeof trpc.requestBetaAccess.useMutation> => {
  const utils = trpc.useUtils()

  return trpc.requestBetaAccess.useMutation({
    onSuccess: () => {
      utils.hasRequestedBetaAccess.invalidate()

      onSuccess?.()
    },
  })
}
