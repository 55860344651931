import {FC} from "react"
import {Outlet} from "react-router-dom"

import {useTranslation} from "@frontend/i18n"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Tabs} from "./components/tabs"
import {useDataFromParams} from "./params"
import {Parts} from "./parts"
import {TABS_TO_DIY_FEEDBACK_TYPE, useCurrentTab} from "./util/tabs"
import {Sidebar} from "../components/sidebar"
import {getRemediationTypeRemediationPageDataOrNotFound} from "../params"
import {useSetBreadcrumbs} from "../util/useSetBreadcrumbs"

export const DiyLayout: FC = () => {
  const t = useTranslation()
  const {causeOnMessageId, caseId, remediationPageData} = useDataFromParams()
  const remediation =
    getRemediationTypeRemediationPageDataOrNotFound(remediationPageData)

  useSetBreadcrumbs({caseId, causeOnMessageId})
  const tab = useCurrentTab()
  const feedbackType = TABS_TO_DIY_FEEDBACK_TYPE[tab]

  const partIcon = remediation.cause.iconName

  return (
    <div
      className={hstack({
        alignItems: "stretch",
        overflowY: "auto",
        marginBottom: 16,
        flexGrow: 1,
        marginTop: 6,
        flexDirection: "column",
        desktop: {
          flexDirection: "row",
          marginRight: 16,
        },
        desktopOnly: {
          marginRight: 0,
          overflowY: "none",
          marginLeft: 8,
        },
      })}
    >
      <Sidebar
        reactionsProps={{
          on: {
            on: "remediation",
            caseId,
            causeId: remediation.cause.id,
            type: feedbackType,
          },
          currentFeedback: remediationPageData.feedback[feedbackType],
        }}
        className={css({
          width: "100%",
          minWidth: 320,
          flexShrink: 0,

          desktop: {
            borderRadius: 16,
            width: "min(339px, 50%)",
            height: "100%",
            overflowY: "auto",
          },
          largeDesktop: {
            width: "25%",
          },
        })}
      >
        <div
          className={vstack({
            alignItems: "stretch",
            gap: 16,
          })}
        >
          <div
            className={vstack({
              alignItems: "stretch",
              paddingY: 8,
              color: "text.primary.onSolid",
            })}
          >
            <h2
              className={css({
                textStyle: "header.s",
              })}
            >
              {t("cause.diy.title")}
            </h2>
            <p
              className={css({
                textStyle: "bodyLargeLight",
              })}
            >
              {remediation.cause.name}
            </p>
          </div>
          <Parts partIcon={partIcon ?? ""} />
        </div>
      </Sidebar>
      <div
        className={vstack({
          flexGrow: 1,
          alignItems: "stretch",
          desktopOnly: {
            height: "calc(100vh - 170px)",
            overflowY: "auto",
            overflowX: "hidden",
            paddingRight: 16,
            marginRight: 0,
          },
          largeDesktop: {
            marginTop: 0,
            overflowY: "hidden",
          },
          desktop: {
            marginLeft: 32,
            paddingTop: 24,
            gap: 24,
          },
        })}
      >
        <Tabs caseId={caseId} causeOnMessageId={remediation.causeOnMessageId} />
        <Outlet />
      </div>
    </div>
  )
}
