const ensureEnvVar = (name: string): string => {
  const value = import.meta.env[name]
  if (!value) {
    throw new Error(`Missing environment variable: ${name}`)
  }

  return value
}

export const API_BASE_URL = ensureEnvVar("VITE_API_BASE_URL")
export const IMAGE_BASE_URL = ensureEnvVar("VITE_IMAGE_BASE_URL")
export const GOOGLE_MAPS_API_KEY = ensureEnvVar("VITE_GOOGLE_MAPS_API_KEY")
export const SENTRY_ENVIRONMENT = ensureEnvVar("VITE_SENTRY_ENVIRONMENT")
export const SKIP_LANDING_PAGE = !!import.meta.env["VITE_SKIP_LANDING_PAGE"]
