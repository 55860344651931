import {FC, useRef, useState} from "react"
import {Trans} from "react-i18next"
import {useNavigate} from "react-router-dom"
import {ReactSVG} from "react-svg"

import {useBreadcrumbs, useCase} from "@frontend/app-container/context"
import {FeedbackButton} from "@frontend/components"
import homeVector from "@frontend/design/icons/home-vector.svg"
import oven from "@frontend/design/icons/oven.svg"
import refrigerator from "@frontend/design/icons/refrigerator.svg"
import washingMachine from "@frontend/design/icons/washing-machine.svg"
import {useTranslation} from "@frontend/i18n"
import {InitialCaseState} from "@frontend/routes/util/initialCaseState"
import {makePath} from "@frontend/routing"
import {useSession} from "@frontend/session"
import {useCreateCaseMutation} from "@frontend/utils/trpc"
import {useBooleanState} from "@frontend/utils/useBooleanState"
import {useEffectOnMount} from "@frontend/utils/useEffectOnMount"
import {css} from "@styled-system/css"
import {hstack, iconsColor, vstack} from "@styled-system/patterns"

import {BaseLayout} from "./cases/[caseId]/components/base-layout"
import {ConsentModal} from "./cases/[caseId]/components/consent-modal"
import {ConversationInputField} from "./cases/[caseId]/components/conversation-input-field"

const MAX_WIDTH = 840

export const LandingPage: FC = () => {
  useBreadcrumbs([])
  useCase()

  const {
    state: showConsentModal,
    setTrue: onShowConsentModal,
    setFalse: onHideConsentModal,
  } = useBooleanState(false)

  const inputRef = useRef<HTMLTextAreaElement>(null)
  const t = useTranslation()

  const {userRcId} = useSession()
  const navigate = useNavigate()

  const [text, setText] = useState("")
  const [preWrittenMessage, setPreWrittenMessage] = useState("")
  const trimmedText = preWrittenMessage ? preWrittenMessage.trim() : text.trim()
  const onCreateCase = (): void => {
    if (!trimmedText) return
    createCaseMutation.mutate({
      userRcId,
    })
  }

  const createCase = (text: string) => () => {
    setPreWrittenMessage(text)
    createCaseMutation.mutate({userRcId})
  }

  const createCaseMutation = useCreateCaseMutation(({caseId}): void => {
    const initialCaseState: InitialCaseState = {
      text: trimmedText,
    }

    navigate(makePath({name: "cases", caseId, ...initialCaseState}))
  })

  const preWrittenMessages = [
    {
      message: t("home.preWrittenMessages.washingMachine"),
      icon: washingMachine,
    },
    {
      message: t("home.preWrittenMessages.refrigerator"),
      icon: refrigerator,
    },
    {
      message: t("home.preWrittenMessages.stoveBurner"),
      icon: oven,
    },
  ]

  useEffectOnMount(() => {
    inputRef.current?.focus()
  })

  return (
    <>
      <BaseLayout>
        <div
          className={vstack({
            minHeight: "100%",
            width: "100%",
            gap: 32,
            paddingY: 24,
            paddingX: {
              base: 16,
              desktop: 0,
            },
            alignItems: {
              base: "stretch",
              desktop: "center",
            },
            desktop: {
              justifyContent: "space-between",
              marginX: 8,
            },
            desktopOnly: {
              marginX: 0,
              paddingX: 48,
            },
          })}
        >
          <div />
          <div
            className={vstack({
              gap: {
                base: 16,
                desktop: 32,
              },
              marginBottom: {
                desktop: 160,
              },
            })}
          >
            <p
              className={css({
                textStyle: "header.m",
                desktop: {
                  textAlign: "center",
                },
                color: "text.primary",
              })}
            >
              {t("home.welcome")}
            </p>
            <div
              className={vstack({
                width: "100%",
                maxWidth: MAX_WIDTH,
                alignItems: "stretch",
                padding: 32,
                borderRadius: 16,
                position: "relative",
                gap: 24,
                backgroundColor: "background.brand.primary",
              })}
            >
              <div
                className={css({
                  position: "absolute",
                  top: "-46px",
                  left: "-46px",
                  display: "none",
                  desktop: {
                    display: "block",
                  },
                })}
              >
                <ReactSVG
                  src={homeVector}
                  className={iconsColor({color: "background.brand.accent"})}
                />
              </div>
              <p
                className={css({
                  textStyle: "bodyXLMedium",
                  color: "text.primary",
                })}
              >
                {t("home.prompt")}
              </p>
              <ConversationInputField
                onChange={setText}
                value={text}
                onSend={onCreateCase}
                onAbort={() => ({})}
                disabled={createCaseMutation.isLoading || !trimmedText}
                inputRef={inputRef}
              />
              <div
                className={css({
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  justifyContent: "flex-start",
                  gap: 8,

                  desktop: {
                    flexDirection: "row",
                    gap: 16,
                  },
                })}
              >
                <p
                  className={css({
                    flexGrow: 0,
                    flexShrink: 0,
                    textStyle: "bodyRegular",
                    color: "text.tertiary",

                    desktop: {
                      alignSelf: "center",
                    },
                  })}
                >
                  {t("home.preWrittenMessages.title")}
                </p>
                {preWrittenMessages.map(({message, icon}) => (
                  <DefaultPrompt
                    key={message}
                    icon={icon}
                    message={message}
                    onClick={createCase(message)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div
            className={css({
              display: "flex",
              flexDirection: "column",
              width: "100%",
              maxWidth: MAX_WIDTH,
              gap: 32,
              paddingY: 16,
              borderTopWidth: 1,
              borderColor: "rgba(240, 240, 240, 1)",

              desktop: {
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              },
            })}
          >
            <p
              className={css({
                textStyle: "captionRegular",
                color: "text.quaternary",
              })}
            >
              <Trans t={t} i18nKey="home.beta">
                <span
                  onClick={onShowConsentModal}
                  className={css({
                    color: "text.brand.accent",
                    cursor: "pointer",
                  })}
                />
              </Trans>
            </p>
            <FeedbackButton css={css.raw({flexGrow: 0, flexShrink: 0})} />
          </div>
        </div>
      </BaseLayout>
      <ConsentModal show={showConsentModal} onClose={onHideConsentModal} />
    </>
  )
}

const DefaultPrompt: FC<{
  message: string
  icon: string
  onClick: () => void
}> = ({message, icon, onClick}) => (
  <button
    onClick={onClick}
    className={hstack({
      gap: 8,
      borderRadius: 8,
      paddingY: 8,
      paddingX: 12,
      backgroundColor: "background.brand.primarySubtle",
      borderWidth: 1,
      borderColor: "border.brand.secondary",
      justifyContent: "flex-start",
      transition: "background-color 0.1s ease-in, box-shadow 0.1s ease-in",
      "&:hover": {
        backgroundColor: "white",
        boxShadow:
          "0px 9px 20px rgba(28, 37, 72, 0.14), 0px 3px 3px rgba(28, 37, 72, 0.06)",
      },
    })}
  >
    <ReactSVG
      src={icon}
      className={iconsColor({color: "foreground.tertiary"})}
    />
    <p
      className={css({
        color: "text.tertiary",
        textStyle: "captionRegular",
        textAlign: "left",
      })}
    >
      {message}
    </p>
  </button>
)
