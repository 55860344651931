import {ComponentProps, FC} from "react"
import {ReactSVG} from "react-svg"

import {useNavigateUpBreadcrumbs} from "@frontend/app-container/context"
import {ReactionsWithForm} from "@frontend/components"
import chevronLeftIcon from "@frontend/design/icons/chevron-left.svg"
import {useTranslation} from "@frontend/i18n"
import {cx} from "@styled-system/css"
import {hstack, iconsColor} from "@styled-system/patterns"

interface Props {
  reactionsProps?: Omit<
    ComponentProps<typeof ReactionsWithForm>,
    "className" | "isInline" | "tooltipPlacement" | "transparent"
  >
  className?: string
}

export const BackBar: FC<Props> = ({reactionsProps, className}) => (
  <div
    className={cx(
      hstack({
        gap: 8,
        justifyContent: "space-between",
        height: {
          base: 72,
          desktop: 60,
        },
        paddingTop: 24,
      }),
      className,
    )}
  >
    <BackButton />
    {reactionsProps && <ReactionsWithForm {...reactionsProps} />}
  </div>
)

const BackButton: FC = () => {
  const onBack = useNavigateUpBreadcrumbs()
  const t = useTranslation()

  return (
    <button
      onClick={onBack}
      className={hstack({
        paddingY: 8,
        gap: 8,
        textStyle: "captionMedium",
        lineHeight: "20px",
        color: "text.secondary.onSolid",
      })}
    >
      <ReactSVG
        src={chevronLeftIcon}
        className={iconsColor({
          color: "foreground.secondaryOnSolid",
        })}
      />
      {t("general.back")}
    </button>
  )
}
