import {FC} from "react"
import {ReactSVG} from "react-svg"

import dislikeIcon from "@frontend/design/icons/dislike.svg"
import likeIcon from "@frontend/design/icons/like.svg"
import {FeedbackRating} from "@ri2/db/client"
import {cva} from "@styled-system/css"

import {TooltipWrapper} from "../ui/tooltip"

interface ReactionProps {
  rating: FeedbackRating
  selected: boolean
  onClick: () => void
  tooltip: string
  className?: string
  transparent?: boolean
  tooltipPlacement?: "top" | "bottom"
  tooltipVariant?: "light" | "dark"
}

const baseStyles = {
  display: "flex",
  alignItems: "center",
  width: 30,
  height: 30,
  borderRadius: "50%",
  justifyContent: "center",
  cursor: "pointer",
}

const styles = cva({
  base: {...baseStyles},
  variants: {
    state: {
      POSITIVE: {
        backgroundColor: "#E9F1E4",
        borderColor: "#9EC289",
        borderWidth: 1,
        "& *": {
          stroke: "#427425",
        },
      },
      NEGATIVE: {
        backgroundColor: "background.error",
        borderColor: "border.errorSubtle",
        borderWidth: 1,
        "& *": {
          stroke: "text.error.primary",
        },
      },
      NONE: {
        backgroundColor: "background.subtle.primary",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "border.brand.primary",
        "& *": {
          stroke: "foreground.brand",
        },
      },
    },
    transparent: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      transparent: true,
      state: "NONE",
      css: {
        backgroundColor: "transparent",
        borderWidth: 0,
      },
    },
  ],
})

export const Reaction: FC<ReactionProps> = ({
  rating,
  selected,
  onClick,
  tooltip,
  tooltipPlacement = "bottom",
  tooltipVariant,
  transparent,
}) => (
  <TooltipWrapper
    content={tooltip}
    positioning={tooltipPlacement}
    variant={tooltipVariant}
  >
    <button
      onClick={onClick}
      aria-label={tooltip}
      className={styles({
        state: rating && selected ? rating : "NONE",
        transparent,
      })}
    >
      <ReactSVG src={rating === "POSITIVE" ? likeIcon : dislikeIcon} />
    </button>
  </TooltipWrapper>
)
