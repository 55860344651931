import {FC} from "react"

import {useBreadcrumbs} from "@frontend/app-container/context"
import {Button} from "@frontend/components"
import {useTranslation} from "@frontend/i18n"
import {makePath} from "@frontend/routing"
import {css} from "@styled-system/css"
import {hstack, vstack} from "@styled-system/patterns"

import {Remediations} from "./components/remediations"
import {Sidebar} from "./components/sidebar"
import {useDataFromParams} from "./params"
import {makeBreadcrumbs} from "./util/breadcrumbs"
import {CauseIcon} from "../../components/causes/cause-icon"
import {CauseIcons} from "../../components/causes/cause-icons"
import {useLastConversationTab} from "../../util/useLastConversationTab"

const CausePage: FC = () => {
  const {
    caseId,
    remediationPageData,
    remediationPageData: {caseName, type},
  } = useDataFromParams()

  const {lastConversationTab} = useLastConversationTab()
  const queryParams = new URLSearchParams({tab: lastConversationTab}).toString()

  const t = useTranslation()

  useBreadcrumbs(
    makeBreadcrumbs({
      queryParams,
      caseId,
      caseName,
      cause:
        type.type === "remediation"
          ? {id: type.causeOnMessageId, name: type.cause.name}
          : "peek",
      t,
    }),
  )

  return (
    <div
      className={css({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        largeDesktopOnly: {
          display: "contents",
        },
        desktopOnly: {
          margin: 16,
          boxSizing: "content-box",
        },
      })}
    >
      <div
        className={hstack({
          alignItems: "stretch",
          gap: 0,
          width: "100%",
          overflow: "hidden",
          marginBottom: 16,
          borderRadius: {
            base: 0,
            desktop: 16,
          },
          largeDesktopDown: {
            flexDirection: "column",
            overflow: "auto",
            height: "100%",
            flex: 1,
            backgroundColor: "#F5F5F5",
            marginBottom: 0,
            paddingBottom: 16,
          },
          largeDesktopOnly: {
            marginRight: 16,
            marginTop: 6,
          },
        })}
      >
        <div
          className={css({
            minHeight: "8px",
            width: "100%",
            backgroundColor: "background.primary",
            display: {
              base: "block",
              desktop: "none",
            },
          })}
        />
        <Sidebar
          className={css({
            width: "100%",
            maxWidth: "100%",
            largeDesktop: {
              maxWidth: "333px",
              overflowY: "auto",
            },
            minHeight: {
              base: "fit-content",
              largeDesktop: "100%",
            },
          })}
        >
          <div
            className={vstack({
              alignItems: "stretch",
              gap: "16px",
              color: "white",
            })}
          >
            {type.type === "peek" ? (
              <CauseIcons
                iconNames={type.peek.causes.map(({iconName}) => iconName)}
              />
            ) : (
              <CauseIcon
                color="colors.background.brand.solidSubtle"
                className={css({flexShrink: 0, flexGrow: 0})}
                iconName={type.cause.iconName}
                iconColor="foreground.primaryOnSolid"
              />
            )}
            <h2
              className={css({
                textStyle: "header.s",
              })}
            >
              {type.type === "remediation"
                ? type.cause.name
                : t("cause.peek.title")}
            </h2>
            <p
              className={css({
                textStyle: "bodyLargeLight",
                color: "text.secondary.onSolid",
              })}
            >
              {type.type === "remediation"
                ? type.cause.description
                : t("cause.peek.description")}
            </p>
            {type.type === "peek" && (
              <Button
                navigateTo={makePath({name: "cases", caseId})}
                title={t("cause.peek.continueDiagnosing")}
                variant="secondaryOnBrand"
                css={{
                  alignSelf: "flex-start",
                }}
              />
            )}
          </div>
        </Sidebar>
        <Remediations
          causeOnMessageId={
            type.type === "peek" ? "peek" : type.causeOnMessageId
          }
          caseModelId={caseId}
          pageData={remediationPageData}
        />
      </div>
    </div>
  )
}

export default CausePage
