import {CSSProperties, FC, PropsWithChildren} from "react"
import {ReactSVG} from "react-svg"

import missingPartImage from "@frontend/design/icons/missing-part.svg"
import {css, cx} from "@styled-system/css"
import {hstack, iconsColor} from "@styled-system/patterns"
import {ColorToken, Token, token} from "@styled-system/tokens"

import {icons} from "./icons"

interface Props extends CauseIconCircleProps {
  iconColor: ColorToken
  iconName: string | null
}

export const CauseIcon: FC<Props> = ({
  color,
  className,
  iconName,
  iconColor,
  borderColor,
  style,
}) => {
  const icon = iconName ? icons[iconName] || missingPartImage : missingPartImage

  return (
    <CauseIconCircle
      color={color}
      className={className}
      borderColor={borderColor}
      style={style}
    >
      <ReactSVG src={icon} className={iconsColor({color: iconColor})} />
    </CauseIconCircle>
  )
}

interface CauseIconCircleProps {
  color: Token
  className?: string
  borderColor?: Token
  style?: CSSProperties
}

export const CauseIconCircle: FC<PropsWithChildren<CauseIconCircleProps>> = ({
  color,
  className,
  borderColor,
  style,
  children,
}) => (
  <div
    className={cx(
      hstack({
        borderRadius: "50%",
        justifyContent: "center",
        alignItems: "center",
      }),
      borderColor
        ? css({width: 60, height: 60, margin: -4, borderWidth: 4})
        : css({width: 56, height: 56}),
      className,
    )}
    style={{
      backgroundColor: token.var(color),
      borderColor: borderColor ? token.var(borderColor) : undefined,
      ...style,
    }}
  >
    {children}
  </div>
)
